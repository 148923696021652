<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div class="container">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-6">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="https://www.hanwha-pm.com/upload/product/20210517/202453eJea.jpg"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-6">
                <div class="mt-4">
                  <h1 class="font-size-20 mb-3">
                    <strong> Product description: </strong>
                  </h1>
                  <h2>
                    Provides the solutions and convenience optimized for a wide
                    range of special processes.
                  </h2>
                  <br />
                  <ul class="container">
                    <li>
                      <h5>
                        Ultra-slim multi-functional odd-shape component mounter
                        / width of 680mm
                      </h5>
                    </li>
                    <li>
                      <h5>Provides best solution for shield can placement</h5>
                    </li>
                    <li>
                      <h5>Improves applicability to odd-shape components</h5>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- end row -->
            <br /><br /><br /><br /><br /><br /><br />
            <h1><strong> Specifications </strong></h1>
            <br /><br />
            <div class="row container" style="font-size: 18px">
              <div class="container-fluid">
                <div class="table-responsive">
                  <table class="table table-nowrap mb-0">
                    <tbody>
                      <tr>
                        <th scope="row" style="width: 400px">speed</th>
                        <td style="font-size: 12px">
                          2,400 CPH / 3,000 CPH (1 Spindle, Optimum / 2 Spindle,
                          Optimum)
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Compatible parts</th>
                        <td style="font-size: 12px">
                          0201~□6mm/~□55mm (H15mm)
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">PCB Size</th>
                        <td style="font-size: 12px">
                          L295 x W250 (Standard) / L250 x W250 (Option)
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Structure</th>
                        <td style="font-size: 12px">
                          2 Spindle x 1 Gantry /1 Spindle x 1 Gantry (Option)
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Mounting degree</th>
                        <td style="font-size: 12px">
                          ±50 μm @ Cpk ≥ 1.0 (Chip) / ±300 μm @ Cpk ≥1.0 (Shield
                          Can)
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <br /><br />
                <h1><strong>Features</strong></h1>
                <br /><br />

                <div class="row">
                  <div class="col-xl-6">
                    <div class="product-detail">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade active show"
                          id="product-4"
                          role="tabpanel"
                        >
                          <div class="product-img">
                            <img
                              src="https://www.hanwha-pm.com/en/resource/images/product/ico_features_smt_6.jpg"
                              alt="img-4"
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end product img -->
                  </div>
                  <div class="col-xl-6">
                    <div class="mt-4">
                      <h1 class="font-size-20 mb-3" style="color: #f60">
                        <strong> High Productivity </strong>
                      </h1>
                      <h3>
                        <strong>Optimized for Shield Can Placement</strong>
                      </h3>
                      <p>
                        Reduces the SMT In-line area by minimizing the machine
                        length, and when placing 8 shield cans, allows
                        production within 16 seconds of cycle time.
                      </p>
                    </div>
                  </div>
                </div>
                <!-- end row -->
                <div class="row">
                  <div class="col-xl-6">
                    <div class="product-detail">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade active show"
                          id="product-4"
                          role="tabpanel"
                        >
                          <div class="product-img">
                            <img
                              src="https://www.hanwha-pm.com/en/resource/images/product/ico_features_smt_5.jpg"
                              alt="img-4"
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end product img -->
                  </div>
                  <div class="col-xl-6">
                    <div class="mt-4">
                      <h1 class="font-size-20 mb-3" style="color: #f60">
                        <strong> High Reliability </strong>
                      </h1>
                      <h3>
                        <strong>
                          Shield Can Placement Inspection Function</strong
                        >
                      </h3>
                      <p>
                        Prevents defects in advance by inspecting the placement
                        of shield cans after placing them.
                      </p>
                    </div>
                  </div>
                </div>
                <!-- end row -->
                <div class="row">
                  <div class="col-xl-6">
                    <div class="product-detail">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade active show"
                          id="product-4"
                          role="tabpanel"
                        >
                          <div class="product-img">
                            <img
                              src="https://www.hanwha-pm.com/en/resource/images/product/ico_features_smt_1.jpg"
                              alt="img-4"
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end product img -->
                  </div>
                  <div class="col-xl-6">
                    <div class="mt-4">
                      <h1 class="font-size-20 mb-3" style="color: #f60">
                        <strong> 
Flexible Production



</strong>
                      </h1>
                      <h3>
                        <strong>
                          Supports Mixed Production</strong
                        >
                      </h3>
                      <p>
                       Possible to produce the top and bottom surfaces of a PCB simultaneously at the front and rear workstations
                      </p>
                    </div>
                  </div>
                </div>
                <!-- end row -->


                <div class="row">
                  <div class="col-xl-6">
                    <div class="product-detail">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade active show"
                          id="product-4"
                          role="tabpanel"
                        >
                          <div class="product-img">
                            <img
                              src="https://www.hanwha-pm.com/en/resource/images/product/ico_features_smt_3.jpg"
                              alt="img-4"
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end product img -->
                  </div>
                  <div class="col-xl-6">
                    <div class="mt-4">
                      <h1 class="font-size-20 mb-3" style="color: #f60">
                        <strong>Easy Operation


</strong>
                      </h1>
                      <h3>
                        <strong>
                        Applies a Recognition Algorithm Dedicated to Shield Can
                        </strong>
                      </h3>
                      <p>
                      Provides automatic component teaching function, editing screen dedicated to shield can, etc. 
                      </p>
                    </div>
                  </div>
                </div>
                <!-- end row -->
              </div>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>